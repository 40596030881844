import { getAppPublicBaseUrl } from 'utils/environment-variables'

const IS_DEV = process.env.NODE_ENV === 'development'

export const isRedirectedFromTypeformNotFound = url => {
  const parsedUrl = new URL(url)
  const utmContentParam = parsedUrl.searchParams.get('utm_content')
  const possibleDeleteParams = ['typeform-deleted', 'typeform-incorrectURL']

  if (possibleDeleteParams.includes(utmContentParam)) {
    return true
  }
  return parsedUrl.searchParams.has('not_found')
}

/**
 * Canonical URL definition:
 * it should start with https
 * it should use www
 * it should end with a trailing slash
 * it shouldn't have query params or anchors besides page
 * example: https://www.typeform.com/templates/
 */
export const toCanonicalURL = (path = '') => {
  const url = new URL(path, getAppPublicBaseUrl())
  // Local environment doesn't support HTTPS
  if (!IS_DEV) {
    url.protocol = 'https'
  }
  const { origin, pathname, searchParams } = url
  const decodedPathname = decodeURIComponent(pathname)

  // Filter out any search parameters that aren't 'page'
  const keys = Array.from(searchParams.keys())
  for (const key of keys) {
    if (key !== 'page') {
      searchParams.delete(key)
    }
  }

  const search = searchParams.toString() ? `?${searchParams.toString()}` : ''

  return origin + `${decodedPathname}/`.replace(/\/*$/, '/') + search
}
