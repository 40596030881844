/* eslint-disable react/no-danger */
import { HREFLANG_LOCALE } from 'constants/prop-types'

import React, { useMemo } from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

import {
  PAGE_CARDCREATOR,
  PAGE_CARDSITE,
  PAGE_OGSITENAME,
  PAGE_PUBLISHER,
} from '../homepage/constants'

import getHrefLangs from './hreflangs'
import getMetaTags from './meta-tags'
import getSocialMetaTags from './social-meta-tags'
import StructuredData from './structured-data'
import getFavicons from './favicons'
import FaqStructure from './faq-structure'

const DocumentHead = props => {
  const {
    availableLocales,
    currentUrl,
    hrefLangLinks,
    title,
    titleSuffix,
    isRetainActive,
    article,
  } = props
  const metaTags = useMemo(() => getMetaTags(props), [props])

  const hrefLangs = getHrefLangs({
    currentUrl,
    hrefLangLinks,
    locales: availableLocales,
  })
  const favicons = useMemo(() => getFavicons(), [])
  const socialMetaTags = useMemo(() => getSocialMetaTags(props), [props])

  return (
    <Head>
      <title key='title'>{`${title}${titleSuffix || ''}`}</title>
      {metaTags}
      {hrefLangs}
      {favicons}
      {socialMetaTags}

      <StructuredData />
      {isRetainActive && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
              a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
              s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
              })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
              profitwell('start', {});
              `,
          }}
          data-pw-auth='3e9cfb440db56df5e327e141de27a4f6'
          id='profitwell-js'
        />
      )}
      <FaqStructure article={article} />
    </Head>
  )
}

DocumentHead.propTypes = {
  availableLocales: PropTypes.arrayOf(HREFLANG_LOCALE),
  hrefLangLinks: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  titleSuffix: PropTypes.string,
  isRetainActive: PropTypes.bool,
  currentUrl: PropTypes.string,
  pageNumber: PropTypes.number,
  article: PropTypes.object,
}

DocumentHead.defaultProps = {
  availableLocales: [],
  hrefLangLinks: null,
  title: '',
  titleSuffix: '',
  currentUrl: '',
  pageNumber: -1,
  cardCreator: PAGE_CARDCREATOR,
  cardSite: PAGE_CARDSITE,
  ogSiteName: PAGE_OGSITENAME,
  publisher: PAGE_PUBLISHER,
  isRetainActive: false,
  article: null,
}

DocumentHead.displayName = 'DocumentHead'

export default DocumentHead
