import { useMemo } from 'react'
import {
  X_DEFAULT_LOCALE,
  DEFAULT_LANGUAGE_CODE,
} from '@typeform/ginger/dist/lib/i18n'

import { toCanonicalURL } from '../../../utils/url-analyzers'

export const useLocaleHrefLangLinks = ({ currentUrl, locales }) => {
  const links = useMemo(() => {
    return locales.map(locale => {
      return {
        href: toCanonicalURL(locale.href),
        hreflang: locale.title.toLowerCase(),
      }
    })
  }, [locales])

  const defaultLink = useMemo(() => {
    const defaultLink =
      links.find(link => link.hreflang === DEFAULT_LANGUAGE_CODE) ?? links[0]

    if (defaultLink) {
      return {
        href: defaultLink.href,
        hreflang: X_DEFAULT_LOCALE,
      }
    }

    return {
      href: toCanonicalURL(currentUrl),
      hreflang: X_DEFAULT_LOCALE,
    }
  }, [currentUrl, links])

  return useMemo(() => [...links, defaultLink], [defaultLink, links])
}
