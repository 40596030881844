/* eslint complexity: 0 */
import { HREFLANG_LOCALE } from 'constants/prop-types'

import PropTypes from 'prop-types'
import { toCanonicalURL } from 'utils/url-analyzers'

import { DEFAULT_OG_TYPE, FALLBCAK_META_IMAGE_URL } from './constants'

const formatLocaleForOpenGraph = localeIso =>
  localeIso
    .split('-')
    .map((curr, i) => (i === 1 ? curr.toUpperCase() : curr))
    .join('_')

const getSocialMetaTags = ({
  availableLocales = [],
  cardCreator,
  cardDescription,
  cardImage,
  cardSite,
  cardTitle,
  ogDescription,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  ogImageAlt,
  ogLocale,
  ogSiteName,
  ogTitle,
  ogType = DEFAULT_OG_TYPE,
  currentUrl,
}) => {
  let result = []
  if (ogType) {
    result.push(<meta key='og:type' content={ogType} property='og:type' />)
  }
  if (!!ogLocale) {
    result.push(
      <meta
        key='og:locale'
        content={formatLocaleForOpenGraph(ogLocale)}
        property='og:locale'
      />
    )
  }
  if (availableLocales.length > 0) {
    result = [
      ...result,
      ...availableLocales
        .filter(available => available.locale.indexOf(ogLocale) !== 0)
        .map(available => (
          <meta
            content={formatLocaleForOpenGraph(available.locale)}
            key={available.locale}
            property='og:locale:alternate'
          />
        )),
    ]
  }

  if (!!ogLocale) {
    result.push(
      <meta
        key='content-language'
        content={ogLocale.toLowerCase()}
        httpEquiv='content-language'
      />
    )
  }

  if (!!ogTitle) {
    result.push(<meta key='og:title' content={ogTitle} property='og:title' />)
  }

  if (!!ogDescription) {
    result.push(
      <meta
        key='og:description'
        content={ogDescription}
        property='og:description'
      />
    )
  }

  if (!!ogImage) {
    result.push(
      <meta key='og:image' content={ogImage} property='og:image' />,
      <meta
        key='og:image:secure_url'
        content={ogImage}
        property='og:image:secure_url'
      />
    )

    if (!!ogImageWidth) {
      result.push(
        <meta
          key='og:image:width'
          content={ogImageWidth}
          property='og:image:width'
        />
      )
    }

    if (!!ogImageHeight) {
      result.push(
        <meta
          key='og:image:height'
          content={ogImageHeight}
          property='og:image:height'
        />
      )
    }

    if (!!ogImageAlt) {
      result.push(
        <meta key='og:image:alt' content={ogImageAlt} property='og:image:alt' />
      )
    }
  } else {
    result.push(
      <meta
        key='og:image'
        content={FALLBCAK_META_IMAGE_URL}
        property='og:image'
      />,
      <meta
        key='og:image:secure_url'
        content={FALLBCAK_META_IMAGE_URL}
        property='og:image:secure_url'
      />,
      <meta key='og:image:width' content='800' property='og:image:width' />,
      <meta key='og:image:height' content='500' property='og:image:height' />,
      <meta
        key='og:image:alt'
        content='Typeform Logo'
        property='og:image:alt'
      />
    )
  }

  if (!!currentUrl) {
    result.push(
      <meta
        key='og:url'
        content={toCanonicalURL(currentUrl)}
        property='og:url'
      />
    )
  }

  if (!!ogSiteName) {
    result.push(
      <meta key='og:site_name' content={ogSiteName} property='og:site_name' />
    )
  }

  if (!!cardTitle) {
    result.push(
      <meta
        key='twitter:card'
        content='summary_large_image'
        property='twitter:card'
      />
    )
    result.push(
      <meta key='twitter:title' content={cardTitle} property='twitter:title' />
    )
  }

  if (!!cardDescription) {
    result.push(
      <meta
        key='twitter:description'
        content={cardDescription}
        property='twitter:description'
      />
    )
  }

  if (!!cardImage) {
    result.push(
      <meta key='twitter:image' content={cardImage} property='twitter:image' />
    )
  } else {
    result.push(
      <meta
        key='twitter:image'
        content={FALLBCAK_META_IMAGE_URL}
        property='twitter:image'
      />
    )
  }

  if (!!cardSite) {
    result.push(
      <meta key='twitter:site' content={cardSite} name='twitter:site' />
    )
  }

  if (!!cardCreator) {
    result.push(
      <meta
        key='twitter:creator'
        content={cardCreator}
        name='twitter:creator'
      />
    )
  }

  return result
}

getSocialMetaTags.propTypes = {
  availableLocales: PropTypes.arrayOf(HREFLANG_LOCALE),
  cardCreator: PropTypes.string,
  cardDescription: PropTypes.string,
  cardImage: PropTypes.string,
  cardSite: PropTypes.string,
  cardSummary: PropTypes.string,
  cardTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  ogImageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ogImageHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ogImageAlt: PropTypes.string,
  ogLocale: PropTypes.string,
  ogSiteName: PropTypes.string,
  ogTitle: PropTypes.string,
  ogType: PropTypes.string,
  ogUrl: PropTypes.string,
}

getSocialMetaTags.defaultProps = {
  availableLocales: [],
  cardCreator: '',
  cardDescription: '',
  cardImage: '',
  cardSite: '',
  cardSummary: '',
  cardTitle: '',
  ogDescription: '',
  ogImage: '',
  ogImageWidth: '',
  ogImageHeight: '',
  ogImageAlt: '',
  ogLocale: '',
  ogSiteName: '',
  ogTitle: '',
  ogType: DEFAULT_OG_TYPE,
  ogUrl: '',
}

export default getSocialMetaTags
