import PropTypes from 'prop-types'
import {
  QUOTE_PROP_TYPES,
  AUDIO_PROP_TYPES,
  IMAGE_PROP_TYPES as GINGER_IMAGE_PROP_TYPES,
} from '@typeform/ginger/dist/constants/prop-types'
import { DEFAULT_LANGUAGE_CODE } from '@typeform/ginger/dist/lib/i18n'

import { LANGUAGES } from './locales'
import { CATEGORIES, PROPERTIES, VERSIONS } from './tracking'

const ALIGNMENT_OPTIONS = ['left', 'center', 'right']

const RAW_HTML_PROP_TYPES = {
  content: PropTypes.shape({
    html: PropTypes.string.isRequired,
    isMarkdown: PropTypes.bool,
  }).isRequired,
  color: PropTypes.string.isRequired,
}

export const ALIGNMENT_OPTION_PROP_TYPES = PropTypes.oneOf(ALIGNMENT_OPTIONS)

const ASSET_ALIGNMENT_OPTIONS = [
  'alternateRightToLeft',
  'alternateLeftToRight',
  'left',
  'right',
]

export const ASSET_ALIGNMENT_PROP_TYPES = PropTypes.oneOf(
  ASSET_ALIGNMENT_OPTIONS
)

const LIST_STYLES = ['none', 'bulleted', 'numbered']

export const LIST_STYLE_PROP_TYPES = PropTypes.oneOf(LIST_STYLES)

const HEADING_LEVELS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
export const HEADING_LEVEL_PROP_TYPES = PropTypes.oneOf(HEADING_LEVELS)

export const HREFLANG_LOCALE = PropTypes.shape({
  locale: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
})

export const NODE_PROP_TYPES = PropTypes.shape({
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  data: PropTypes.object,
  marks: PropTypes.array,
  nodeType: PropTypes.string,
  value: PropTypes.string,
})

export const ASSET_PROP_TYPES = {
  contentType: PropTypes.string,
  details: PropTypes.shape({
    size: PropTypes.number,
    image: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  fileName: PropTypes.string,
  url: PropTypes.string,
}

export const TARGET_PROP_TYPE = PropTypes.oneOf([
  '_self',
  '_blank',
  '_parent',
  '_top',
])

export const CTA_PROP_TYPES = {
  background: PropTypes.string,
  buttonSize: PropTypes.string,
  contentTextSize: PropTypes.number,
  isInlined: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  target: TARGET_PROP_TYPE,
  url: PropTypes.string.isRequired,
}

export const RICH_TEXT_PROP_TYPES = PropTypes.shape({
  contentType: PropTypes.string,
  html: NODE_PROP_TYPES,
  data: PropTypes.object,
})

export const SECTION_PROP_TYPES = {
  background: PropTypes.string,
  containerSizeOverride: PropTypes.number,
  contentAlignment: ALIGNMENT_OPTION_PROP_TYPES,
  contentTextSize: PropTypes.number,
  description: PropTypes.oneOfType([RICH_TEXT_PROP_TYPES, PropTypes.bool]),
  descriptionAlignment: ALIGNMENT_OPTION_PROP_TYPES,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  headingAlignment: ALIGNMENT_OPTION_PROP_TYPES,
  headingColor: PropTypes.string,
  headingLevel: HEADING_LEVEL_PROP_TYPES,
  lazyLoadAssets: PropTypes.bool,
  padY: PropTypes.number,
  textColor: PropTypes.string,
}

export const GRID_ITEM_PROP_TYPES = {
  id: PropTypes.string,
  props: PropTypes.shape({
    asset: PropTypes.shape(ASSET_PROP_TYPES),
    cta: PropTypes.shape({
      id: PropTypes.string,
      props: PropTypes.shape(CTA_PROP_TYPES),
    }),
    description: RICH_TEXT_PROP_TYPES,
    heading: PropTypes.string,
    headingLevel: HEADING_LEVEL_PROP_TYPES,
  }),
}

export const CAROUSEL_ITEM_PROP_TYPES = {
  asset: PropTypes.shape(ASSET_PROP_TYPES),
  description: RICH_TEXT_PROP_TYPES,
  title: PropTypes.string,
  url: PropTypes.string,
}

export const IMAGE_PROP_TYPES = PropTypes.shape({
  fields: PropTypes.object,
})

export const PAGE_PROPS = {
  availableLocales: PropTypes.arrayOf(HREFLANG_LOCALE),
  pageContent: PropTypes.object,
  locale: PropTypes.oneOf(LANGUAGES),
  language: PropTypes.oneOf(LANGUAGES),
  userCountryCode: PropTypes.string,
}

export const PAGE_DEFAULT_PROPS = {
  availableLocales: [],
  locale: DEFAULT_LANGUAGE_CODE,
  language: DEFAULT_LANGUAGE_CODE,
  pageContent: {},
  userCountryCode: null,
}

export const TYPEFORM_PROP_TYPES = {
  content: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export const AUTHOR_PROP_TYPES = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bio: RICH_TEXT_PROP_TYPES,
})

export const CATEGORY_PROP_TYPES = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
})

export const IMAGE_CAPTION_PROP_TYPES = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
}

export const VIDEO_PROP_TYPES = {
  content: PropTypes.shape({
    title: PropTypes.string,
    wistiaVideoId: PropTypes.string,
    youtubeVideoId: PropTypes.string,
  }),
}

export const HIGHLIGHTED_LINK_PROPTYPES = {
  color: PropTypes.string,
  content: NODE_PROP_TYPES,
}

export const DROP_CAP_PROPTYPES = {
  color: PropTypes.string.isRequired,
  content: NODE_PROP_TYPES,
}

export const FULL_WIDTH_IMAGE_PROPTYPES = {
  content: PropTypes.shape({
    image: PropTypes.shape(GINGER_IMAGE_PROP_TYPES),
  }),
  disableLazyLoad: PropTypes.bool,
}

export const EMBEDDED_AUDIO_PROP_TYPES = {
  content: PropTypes.shape({
    audioFile: AUDIO_PROP_TYPES,
  }),
}

export const SECTION_DIVIDER_PROP_TYPES = {
  content: PropTypes.shape({
    bookmarkSlug: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string,
    sectionTitleColor: PropTypes.string,
    backgroundImage: PropTypes.shape(GINGER_IMAGE_PROP_TYPES),
    backgroundColor: PropTypes.string,
    noBackground: PropTypes.bool,
  }),
  onComponentInView: PropTypes.func,
}

export const HIGHLIGHTS_PAGE_SECTION_PROP_TYPES = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      author: AUTHOR_PROP_TYPES,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      mainImage: PropTypes.shape(GINGER_IMAGE_PROP_TYPES),
      readTime: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    })
  ),
  category: CATEGORY_PROP_TYPES,
}

const CTA_CONTENT_PROP_TYPES = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
  tagline: PropTypes.string,
  uppercase: PropTypes.bool,
  verticalPadding: PropTypes.number,
  borderWidth: PropTypes.number,
  borderRadius: PropTypes.number,
  fontWeight: PropTypes.number,
}

export const BLOG_CTA_PROP_TYPES = {
  color: PropTypes.string.isRequired,
  content: PropTypes.shape(CTA_CONTENT_PROP_TYPES),
}

export const BLOG_RICH_TEXT_PROP_TYPES = PropTypes.shape({
  blocks: PropTypes.arrayOf(
    PropTypes.oneOfType([
      NODE_PROP_TYPES,
      PropTypes.shape(BLOG_CTA_PROP_TYPES),
      PropTypes.shape(HIGHLIGHTED_LINK_PROPTYPES),
      PropTypes.shape(QUOTE_PROP_TYPES),
      PropTypes.shape(VIDEO_PROP_TYPES),
      PropTypes.shape(TYPEFORM_PROP_TYPES),
      PropTypes.shape(RAW_HTML_PROP_TYPES),
      PropTypes.shape(DROP_CAP_PROPTYPES),
      PropTypes.shape(FULL_WIDTH_IMAGE_PROPTYPES),
      PropTypes.shape(EMBEDDED_AUDIO_PROP_TYPES),
      PropTypes.shape(SECTION_DIVIDER_PROP_TYPES),
      PropTypes.shape(IMAGE_CAPTION_PROP_TYPES),
    ])
  ),
})

const PRICING_PLAN_PRICE_PROP_TYPE = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  base_amount: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  tax_amount: PropTypes.number.isRequired,
  tax_rate: PropTypes.number.isRequired,
  currency: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  policy: PropTypes.string,
})

const PRICING_PLAN_BILLING_TYPE_PROP_TYPE = PropTypes.shape({
  price: PRICING_PLAN_PRICE_PROP_TYPE.isRequired,
  discountedPrice: PRICING_PLAN_PRICE_PROP_TYPE.isRequired,
})

export const PRICING_PLAN_PROP_TYPE = PropTypes.shape({
  pricing: PropTypes.shape({
    monthly: PRICING_PLAN_BILLING_TYPE_PROP_TYPE.isRequired,
    yearly: PRICING_PLAN_BILLING_TYPE_PROP_TYPE.isRequired,
  }),
})

export const TRACKING_PROP_TYPE = PropTypes.shape({
  item: PropTypes.string.isRequired,
  item_type: PropTypes.string.isRequired,
  category: PropTypes.oneOf(CATEGORIES).isRequired,
  typeform_version: PropTypes.oneOf(VERSIONS).isRequired,
  typeform_property: PropTypes.oneOf(PROPERTIES).isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  location: PropTypes.string,
})
