/*eslint no-unused-vars: [0, { "argsIgnorePattern": "^_" }]*/
import PropTypes from 'prop-types'
import { memo } from 'react'

//Regex to get everything between H tags
const betweenHeaderTags = /^<(h[1-6]+).*>(.*)<\/\1>/i
const faqRegex = /faq/i
const scriptRegex = /<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/gi

//Schema
const questionSchema = heading => ({
  '@type': 'Question',
  'name': heading,
  'acceptedAnswer': {
    '@type': 'Answer',
    'text': '',
  },
})

export const generateFaqSchema = body => {
  const questions = []
  let faqIndex = -1

  body.split('\n').forEach(line => {
    const [_fullMatch, _tag, heading] =
      line.trim().match(betweenHeaderTags) ?? []

    //If heading increment the questionIndex and add to questions array, otherwise add to answer
    if (heading) {
      faqIndex++
      questions[faqIndex] = questionSchema(heading)
    } else if (faqIndex >= 0) {
      questions[faqIndex].acceptedAnswer.text += line
    }
  })

  //Remove script tags from answers
  const cleanedQuestions = questions.map(q => ({
    ...q,
    acceptedAnswer: {
      ...q.acceptedAnswer,
      text: q.acceptedAnswer.text.replace(scriptRegex, ''),
    },
  }))

  //JSON-LD structure https://developers.google.com/search/docs/advanced/structured-data/faqpage
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': cleanedQuestions.filter(
      q => q.acceptedAnswer.text.trim() !== ''
    ),
  }
}

const FaqStructure = ({ article }) => {
  if (!article || !faqRegex.test(article.label_names.join(' '))) {
    return null
  }

  const html = { __html: JSON.stringify(generateFaqSchema(article.body)) }
  return (
    <script
      key='faq-structure'
      data-testid='faq-structure'
      dangerouslySetInnerHTML={html}
      type='application/ld+json'
    />
  )
}

FaqStructure.propTypes = {
  article: PropTypes.shape({
    label_names: PropTypes.array,
    body: PropTypes.string,
  }),
}

FaqStructure.defaultProps = {
  article: null,
}

FaqStructure.displayName = 'FaqStructure'

export default memo(FaqStructure)
