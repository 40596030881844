/* eslint-disable react/no-danger */
import typeformsStructuredData from './structured-data.json'

const html = { __html: JSON.stringify(typeformsStructuredData) }

const StructuredData = () => {
  return <script dangerouslySetInnerHTML={html} type='application/ld+json' />
}

export default StructuredData
