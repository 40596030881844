import { IMAGE_CDN_URL } from 'constants/url'

import PropTypes from 'prop-types'
import { getStaticAsset } from 'utils/assets'
import { toCanonicalURL } from 'utils/url-analyzers'

const getMetaTags = ({
  description,
  publisher,
  canonical,
  currentUrl,
  noIndex,
  isUsingContentfulImageAsset,
  pageNumber,
}) => {
  const result = []

  let canonicalUrl = canonical || currentUrl
  if (pageNumber > 1 && canonical) {
    canonicalUrl = `${canonical}?page=${pageNumber}`
  }

  result.push(<meta key='charset' charSet='utf-8' />)
  result.push(
    <link
      key='manifest'
      href={getStaticAsset('manifest.json')}
      rel='manifest'
    />
  )
  result.push(
    <link key='canonical' href={toCanonicalURL(canonicalUrl)} rel='canonical' />
  )
  result.push(
    <link key='handheld' href={toCanonicalURL(canonicalUrl)} rel='alternate' />
  )

  result.push(
    <meta
      key='viewport'
      content='width=device-width, initial-scale=1.0'
      name='viewport'
    />
  )

  if (!!description) {
    result.push(
      <meta key='description' content={description} name='description' />
    )
  }

  if (noIndex) {
    result.push(<meta key='robots' content='noindex, nofollow' name='robots' />)
  }

  if (!!publisher) {
    result.push(<link key='publisher' href={publisher} rel='publisher' />)
  }

  if (!!isUsingContentfulImageAsset) {
    result.push(
      <link href={IMAGE_CDN_URL} key='contentful-preconnect' rel='preconnect' />
    )
    result.push(
      <link
        href={IMAGE_CDN_URL}
        key='contentful-dns-prefetch'
        rel='dns-prefetch'
      />
    )
  }

  result.push(
    <meta
      key='facebook-domain-verification'
      content='7wr2jyy3v93ogsfohbxtdtu6ty5zlm'
      name='facebook-domain-verification'
    />
  )

  return result
}

getMetaTags.defaultProps = {
  noIndex: false,
  isUsingContentfulImageAsset: false,
  description: '',
  hreflangEs: '',
  hreflangEn: '',
  publisher: '',
  canonical: '',
  currentUrl: '',
  pageNumber: -1,
}

getMetaTags.propTypes = {
  description: PropTypes.string,
  hreflangEs: PropTypes.string,
  hreflangEn: PropTypes.string,
  publisher: PropTypes.string,
  canonical: PropTypes.string,
  currentUrl: PropTypes.string,
  noIndex: PropTypes.bool,
  isUsingContentfulImageAsset: PropTypes.bool,
  pageNumber: PropTypes.number,
}

export default getMetaTags
