import React from 'react'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

const getFavicons = () => {
  return [
    <link
      key='apple-touch-icon'
      href='/apple-touch-icon.png'
      rel='apple-touch-icon'
      sizes='180x180'
    />,
    <link
      key='favicon-32x32'
      href='/favicon-32x32.png'
      rel='icon'
      sizes='32x32'
      type='image/png'
    />,
    <link
      key='favicon-16x16'
      href='/favicon-16x16.png'
      rel='icon'
      sizes='16x16'
      type='image/png'
    />,
    <link key='manifest' href='/site.webmanifest' rel='manifest' />,
    <link
      key='safari-icon'
      color={NeutralColors.Ink}
      href='/safari-pinned-tab.svg'
      rel='mask-icon'
    />,
    <meta
      key='msapplication-TileColor'
      content={NeutralColors.Ink}
      name='msapplication-TileColor'
    />,
    <meta key='theme-color' content={NeutralColors.Ink} name='theme-color' />,
  ]
}

export default getFavicons
